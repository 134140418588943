import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import audit from './audit'
import order from './order'
import task from './task'
import data from './data'
import {local, LOCAL_STORAGE} from '@/utils/storage'
import { getParameterFromUrl } from '@/utils/index'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "notice" */ '@/views/notice/index.vue'),
    meta: {
      title: '公告详情'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy.vue'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: () => import('@/views/protocol.vue'),
    meta: {
      title: '用户协议'
    }
  },
  ...order,
  ...audit,
  ...data,
  ...task
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const urlToken = getParameterFromUrl('token');
  const urlCompanyId = getParameterFromUrl('companyId');

  // let shouldUpdate = false;

  if (urlToken && urlToken !== local.get(LOCAL_STORAGE.token)) {
    local.set(LOCAL_STORAGE.token, urlToken);
    // shouldUpdate = true;
  }

  if (urlCompanyId && urlCompanyId !== local.get(LOCAL_STORAGE.company)) {
    local.set(LOCAL_STORAGE.company, urlCompanyId);
    // shouldUpdate = true;
  }

  // if (shouldUpdate) {
  //   await store.dispatch('user/clearUserInfo');
  //   await store.dispatch('user/getUserInfo');
  // }

  next();
});


router.afterEach((to, from) => {
  document.title = <string>to.meta.title
})

export default router
